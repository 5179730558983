import React from 'react';
import './App.scss';
import Embed from './components/Embed/Embed';

function App() {
  return (
    <div className="App">
      <Embed />
    </div>
  );
}

export default App;
