import React from 'react';
import { Button, Col, Container, Row, Form, FormGroup, Label, Input, FormFeedback, ButtonGroup } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import qs from 'qs';
import settings from '../../settings';

import './Embed.scss'
import Icon from '../Icon/Icon';
// import './bootstrap.css'

const atob = require('atob');

class Embed extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      otherParams: {},
      redirectUrl: '',
      GPA: 0,
      SAT: 0,
      earlyApp: false,
      private: false,
      isLoading: false,
      schools: [],
      interval: '',
      numberOfRuns: 0
    }
  }

  componentDidMount() {
    if (!window?.location?.search) {
      return;
    }

    let params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    let update = {}
    if (params.token) {
      update.token = params.token;
    }

    if (params.redirectUrl) {
      update.redirectUrl = params.redirectUrl;
    }

    delete params.token;
    delete params.redirectUrl;

    update.otherParams = params;

    this.setState(update);
  }

  handleSubmitClick = () => {
    let button = document.getElementById('schools-submit');
    if (button) {
      button.click();
    }
  }

  handleSubmit = () => {
    if (this.state.GPA >= 0 && this.state.GPA <= 4 && this.state.SAT >= 0 && this.state.SAT <= 1600 && !this.state.isLoading) {
      this.setState({ GPA: this.state.GPA ? this.state.GPA : 0, SAT: this.state.SAT ? this.state.SAT : 0, isLoading: true }, () => {
        this.timer = setTimeout(() => {
          callApi(this.state.GPA, this.state.SAT, this.state.earlyApp, this.state.private)
            .then(res => {
              let numberOfRuns = this.state.numberOfRuns + 1;
              this.setState({
                schools: res.data.splice(0, 3),
                isLoading: false,
                numberOfRuns,
              });
            })
            .catch(err => {
              console.log(err);
              this.setState({
                isLoading: false,
                schools: []
              });
            });
        }, this.WAIT_INTERVAL);
      })
    }
  }

  renderSchoolsLoading = () => {
    var loading = [];
    for (var i = 0; i < 3; i++) {
      loading.push(
        <Col xs='4' sm='4' md='4' key={'school' + i} className="px-1">
          <div className="college-cell">
            <Row className="align-items-center">
              <Col xs='9' sm='9' className="school-name">
              </Col>
              <Col xs="3">
                <div className="score-box">
                  <div style={{ zIndex: '10', position: 'relative', color: '#0f629b', marginLeft: '-12px' }}><FontAwesome name='spinner' spin /></div>
                  <div className='score-color-background' />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      )
    }

    return loading;
  }

  renderSchools = () => {
    return this.state.schools.map((school, index) => {
      return (
        <Col xs='12' sm='4' md='4' key={'school' + index} className="px-1">
          <div className="college-cell">
            <Row className="align-items-center" style={{ height: '100%' }}>
              <Col xs='9' sm='9' className="school-name" title={school.name}>
                {school.name}
              </Col>
              <Col xs="3">
                <div className="score-box" style={this.setScoreColor(school.chanceOfAdmission)}>
                  <div style={{ zIndex: '10', position: 'relative', marginLeft: '-12px' }}>{school.chanceOfAdmission + "%"}</div>
                  <div className='score-color-background' style={this.setScoreColor(school.chanceOfAdmission)} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      )
    })
  }

  setScoreColor = (chanceOfAdmission) => {
    var backgroundColor;
    var color = '#fff';

    if (chanceOfAdmission < 12) { backgroundColor = '#ff210a' } else                // red
      if (chanceOfAdmission < 24) { backgroundColor = '#fe8d1c' } else
        if (chanceOfAdmission < 37) {
          backgroundColor = '#fed903';
          color = '#949494';
        } else if (chanceOfAdmission < 49) { backgroundColor = '#ced42c' } else
          if (chanceOfAdmission < 62) { backgroundColor = '#b1d42c' } else
            if (chanceOfAdmission < 74) { backgroundColor = '#47dc66' } else
            //if (chanceOfAdmission < 87) { backgroundColor = '#6BBD7B' } else
            { backgroundColor = '#00cf81' }                                   // green

    return ({
      background: backgroundColor,
      color: color
    });
  }

  renderTestWidget = () => {
    return (
      <div className='test-widget'>
        <Container style={{ color: 'black' }} fluid>
          <Row>
            <Form onSubmit={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}>
              <Col xs='12' className='widget'>
                <Row className='align-items-center'>
                  <Col xs='12' style={{ fontWeight: 'bold', fontSize: '24px', padding: '15px', borderBottom: '1px solid #ddd', background: '#f6f6f6', borderRadius: '4px 4px 0 0' }}>
                    <div className='d-block d-md-flex align-items-center'>
                      <div>Applying to college? Learn your chances.</div>
                      {this.state.numberOfRuns > 0
                        ? <div className='desktop-run-btn ml-auto d-md-block'>
                          <Button size='lg' color='info' style={{ float: 'right', marginTop: '2px', color: 'white' }} type='submit'>
                            More Schools<FontAwesome name='flask' style={{ marginLeft: '8px', height: '21px' }} />
                            <Icon icon='flask' style={{ color: 'white' }} />
                          </Button>
                        </div>
                        : ''}
                    </div>
                  </Col>
                </Row>
                <Row style={{ padding: '6px 15px 4px 15px' }}>
                  <Col xs='3' sm='3' md='3' className='widget-input'>
                    <Label>Unweighted GPA</Label>
                    <Input type='number' step="0.01" onChange={(e) => { this.setState({ GPA: e.target.value }) }} value={this.state.GPA} invalid={this.state.GPA > 4 || this.state.GPA < 0} />
                    <FormFeedback>Must be less than 4.0</FormFeedback>
                  </Col>
                  <Col xs='3' sm='3' md='3' className='widget-input'>
                    <Label>SAT or ACT Score</Label>
                    <Input type='number' onChange={(e) => { this.setState({ SAT: e.target.value }) }} value={this.state.SAT} invalid={this.state.SAT > 1600 || this.state.SAT < 0} />
                    <FormFeedback>Must be less than 1600</FormFeedback>
                  </Col>
                  <Col xs='3' sm='3' md='3' className='widget-radio'>
                    <FormGroup tag="fieldset">
                      <Label>Applying Early?</Label>
                      <br></br>
                      <FormGroup check inline>
                        <Label check className="custom-radio" onClick={() => this.setState({ earlyApp: false })}>
                          <Input
                            checked={!this.state.earlyApp}
                            readOnly
                            type="radio"
                            className="custom-control-input"
                          />{' '}
                          <span className="custom-control-indicator"></span>
                          <span className="custom-control-description">No</span>
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check className="custom-radio" onClick={() => this.setState({ earlyApp: true })}>
                          <Input
                            checked={this.state.earlyApp}
                            readOnly
                            type="radio"
                            className="custom-control-input"
                          />{' '}
                          <span className="custom-control-indicator"></span>
                          <span className="custom-control-description">Yes</span>
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col xs='3' sm='3' md='3' className='widget-radio'>
                    <FormGroup tag="fieldset">
                      <Label>My high school is:</Label>
                      <br></br>
                      <FormGroup check inline>
                        <Label check className="custom-radio" onClick={() => this.setState({ private: false })}>
                          <Input
                            checked={!this.state.private}
                            readOnly
                            type="radio"
                            className="custom-control-input"
                          />{' '}
                          <span className="custom-control-indicator"></span>
                          <span className="custom-control-description">Public</span>
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check className="custom-radio" onClick={() => this.setState({ private: true })}>
                          <Input
                            checked={this.state.private}
                            readOnly
                            type="radio"
                            className="custom-control-input"
                          />{' '}
                          <span className="custom-control-indicator"></span>
                          <span className="custom-control-description">Private</span>
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  {this.state.isLoading
                    ? this.renderSchoolsLoading()
                    : this.state.numberOfRuns > 0
                      ? this.renderSchools()
                      : <Col xs='12' sm='12' md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} style={{ textAlign: 'center' }} className='initial-run-btn'>
                        <div style={{ paddingBottom: '16px' }}>Try a limited sample version of CollegeLab's Predictor using 4 of their 12 data points. Their algorithm is one of the many tools we have to help you navigate the college admissions process.</div>
                      </Col>}
                  <Button style={{ display: 'none' }} type='submit' id='schools-submit'>submit</Button>
                </Row>
              </Col>
            </Form>
          </Row>
        </Container>
      </div>
    )
  }

  renderFooter = () => {
    let additionalParams = '';
    if (Object.keys(this.state.otherParams).length > 0) {
      additionalParams = '?';
      Object.keys(this.state.otherParams).forEach(key => {
        additionalParams = additionalParams.concat(`${key}=${this.state.otherParams[key]}&`);
      })
    }

    if (this.state.numberOfRuns == 0) {
      return (
        <>
          <Col xs='12' sm='12' style={{ textAlign: 'center', paddingBottom: '20px' }}>
            {/* <ButtonGroup> */}
              <Button color='info' style={{ color: 'white', width: "250px" }} size='lg' type='button' onClick={this.handleSubmitClick}>Tell me my Chances<Icon icon='flask' style={{ marginLeft:'8px', color: 'white' }} /></Button>
              {/* <Button style={{ color: 'white', width: "250px" }} size='lg' color='primary' href={`${settings.TARGET_URL}/signup/${this.state.token}${additionalParams}`} target="_blank"> */}
              <Button style={{ color: 'white', width: "250px" }} size='lg' color='primary' href={atob(this.state.redirectUrl)} target="_blank">
                Get CollegeLab!
              </Button>
            {/* </ButtonGroup> */}
          </Col>
        </>
      )
    } else {
      return (
        <Col xs='12' style={{ textAlign: 'center', paddingBottom: '16px' }}>
          <Button style={{ color: 'white', width: "250px" }} size='lg' color='primary' href={atob(this.state.redirectUrl)} target="_blank">
            Get CollegeLab!
          </Button>
        </Col>
      )
    }
  }

  render() {
    return (
      <>
        <Container style={{minHeight:'356px'}} fluid>
          <Row className='shadow border' style={{ borderRadius: '4px' }}>
            {this.renderTestWidget()}
            {this.renderFooter()}
          </Row>
          <Row className='text-right'>
            <div className='ml-auto'>
              <small>Powered by <a href="https://collegelab.org" target='_blank'>CollegeLab</a></small>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

export default Embed;

const callApi = async (GPA, SAT, earlyApp, privateSchool) => {
  const response = await fetch('/api/v1/random', {
    method: "POST",
    body: JSON.stringify({
      GPA: GPA.toString(),
      SAT: SAT.toString(),
      earlyApp: earlyApp ? '1' : '-1',
      private: privateSchool ? '1' : '-1'
    }),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response
  }, function (error) {
    console.log(error);
  });

  const body = await response.json();
  if (response.status !== 200) {
    throw Error(body.message);
  }

  return body;
};